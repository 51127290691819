import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import {
  CommunicationEffects,
  CommunicationState,
  communicationReducer,
} from './communication';
import {
  CommunicationResponseState,
  communicationResponseReducer,
  CommunicationResponseEffects,
} from './communication-response';
import { RootEffects } from './root/root.effects';
import { AdminState, adminReducer, AdminEffects } from './admin';
import { RootState, rootReducer } from './root/root.reducers';

export interface TelematicLabState {
  communication: CommunicationState;
  communicationResponse: CommunicationResponseState;
  admin: AdminState;
  root: RootState;
}

export const TelematicLabReducers: ActionReducerMap<TelematicLabState, any> = {
  communication: communicationReducer,
  communicationResponse: communicationResponseReducer,
  admin: adminReducer,
  root: rootReducer,
};

export const TelematicLabEffects = [
  EffectsModule.forFeature([CommunicationEffects]),
  EffectsModule.forFeature([CommunicationResponseEffects]),
  EffectsModule.forFeature([AdminEffects]),
  EffectsModule.forFeature([RootEffects]),
];
