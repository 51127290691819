import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RootActions } from './root.actions';

export interface RootState extends EntityState<any> {
  email: string;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: RootState = adapter.getInitialState({
  email: '',
});

const _rootReducer = createReducer(
  initialState,
  on(RootActions.SaveUsersMail, (state, payload) => ({
    ...state,
    email: payload.mail,
  })),
);
export function rootReducer(state, action: Action): RootState {
  return _rootReducer(state, action);
}
