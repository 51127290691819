import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Menu } from '@design-system/feature/app-wrapper-v2';
import { ROLES } from './roles';
import { UserService } from '@features/auth';
import { filterTruthy } from '@shared-lib/rxjs';

@Component({
  selector: 'tl-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  menu: Menu = {
    title: 'Telematic Lab',
    titleIcon: 'pd-telematic-lab',
    navigation: [
      {
        title: 'telematic_lab.communication.name',
        icon: 'wifi_protected_setup',
        routerLink: '/communication',
      },
      {
        title: 'telematic_lab.communication_response.name',
        icon: 'reorder',
        routerLink: '/communication-response',
      },
    ],
  };

  private readonly _destroy$ = new Subject<void>();

  constructor(private _userService: UserService) {
    this._userService.currentUser
      .pipe(filterTruthy(), takeUntil(this._destroy$))
      .subscribe(() => {
        if (this._userService.hasOneRole([ROLES.TELEMATICLAB_ADMIN]))
          this.menu.navigation.push({
            title: 'telematic_lab.admin.name',
            icon: 'policy',
            routerLink: '/admin',
          });
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
